import { Card, message } from 'antd';
import { Link } from 'gatsby';

import React, { useEffect, useMemo } from 'react';
import { Helmet } from 'react-helmet';
import Layout from '../components/Layout';
import { QuickForm, QuickFormProps } from '../components/QuickForm';
import { useAppContext } from '../modules/app';
import useCurrentUser from '../modules/app/useCurrentUser';
import { useTableUpdate } from '../modules/row-data/queryHooks';
import { getFormFields } from '../modules/row-data/utils';
import createPrivatePage from '../utils/createPrivatePage';

function Profile() {
  const { resources } = useAppContext();
  const { data: currentUser } = useCurrentUser();
  const [update, updateResult] = useTableUpdate('User');
  const userResource = resources.User;
  const { colFields } = userResource;

  useEffect(() => {
    if (updateResult.data) {
      message.success('Data saved');
    }
  }, [updateResult.data]);

  const fields = useMemo(() => {
    return getFormFields({ colFields } as any, 'update');
  }, [colFields]);

  const initialValues = currentUser;

  const onFinish: QuickFormProps['onFinish'] = (values) => {
    update({
      variables: {
        pk: currentUser.id,
        data: values,
      },
    });
  };

  return (
    <Layout
      breadcrumb={[
        { title: 'Home', path: '/' },
        { title: 'Profile', path: '/profile' },
      ]}
    >
      <Helmet>
        <title>Profile</title>
      </Helmet>
      <Card
        title="Profile"
        loading={!initialValues}
        extra={<Link to="/change-password">Change Password</Link>}
      >
        {initialValues && (
          <QuickForm
            layout="vertical"
            name="basic"
            fields={fields}
            initialValues={initialValues}
            onFinish={onFinish}
            actionButtons={[
              {
                children: 'Submit',
                htmlType: 'submit',
                type: 'primary',
                loading: updateResult.loading,
                disabled: updateResult.loading,
              },
            ]}
          />
        )}
      </Card>
    </Layout>
  );
}

export default createPrivatePage(Profile);
